@font-face {
  font-family: 'Forza-Book';
  src: url('./fonts/HCo_Forza/OpenType/Forza-Thin.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Forza-Book';
  src: url('./fonts/HCo_Forza/OpenType/Forza-Book.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Forza-Book';
  src: url('./fonts/HCo_Forza/OpenType/Forza-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Forza-Book';
  src: url('./fonts/HCo_Forza/OpenType/Forza-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Forza-Book';
  src: url('./fonts/HCo_Forza/OpenType/Forza-Black.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'Forza-Book-Italic';
  src: url('./fonts/HCo_Forza/OpenType/Forza-BookItalic.otf') format('opentype');
  font-style: 'italic';
}

@font-face {
  font-family: 'Saira-Condensed-Thin';
  src: url('./fonts/Saira_Condensed/SairaCondensed-Thin.ttf') format('trueType');
  font-weight: 100;
}

@font-face {
  font-family: 'Saira-Condensed-Extra-Light';
  src: url('./fonts/Saira_Condensed/SairaCondensed-ExtraLight.ttf') format('trueType');
  font-weight: 200;
}

@font-face {
  font-family: 'Saira-Condensed';
  src: url('./fonts/Saira_Condensed/SairaCondensed-Light.ttf') format('trueType');
  font-weight: 300;
}

@font-face {
  font-family: 'Saira-Condensed';
  src: url('./fonts/Saira_Condensed/SairaCondensed-Regular.ttf') format('trueType');
  font-weight: 400;
}

@font-face {
  font-family: 'Saira-Condensed';
  src: url('./fonts/Saira_Condensed/SairaCondensed-Medium.ttf') format('trueType');
  font-weight: 500;
}

@font-face {
  font-family: 'Saira-Condensed-Semi-Bold';
  src: url('./fonts/Saira_Condensed/SairaCondensed-SemiBold.ttf') format('trueType');
  font-weight: 600;
}

@font-face {
  font-family: 'Saira-Condensed';
  src: url('./fonts/Saira_Condensed/SairaCondensed-Bold.ttf') format('trueType');
  font-weight: 700;
}

@font-face {
  font-family: 'Saira-Condensed';
  src: url('./fonts/Saira_Condensed/SairaCondensed-ExtraBold.ttf') format('trueType');
  font-weight: 800;
}

@font-face {
  font-family: 'Saira-Condensed-Black';
  src: url('./fonts/Saira_Condensed/SairaCondensed-Black.ttf') format('trueType');
  font-weight: 900;
}
